import React from 'react';
import './App.css';
import Layout from './app/components/Layout/Layout';
import { BrowserRouter } from 'react-router-dom';

function App() {
  return (
    <BrowserRouter>
      <Layout
        header={<div id='single-spa-application:header'></div>}
        left={<div id='single-spa-application:menu'></div>}
        right={
          <>
            <div id='single-spa-application:integraciones'></div>
          </>
        }
      ></Layout>
    </BrowserRouter>
  );
}

export default App;
