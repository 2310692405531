import Cookies from 'universal-cookie';
import axios from 'axios';
const COMMERCE_URI = '/api/v1/bff-establishment/private/commerce';

export const setCookies = (token, ttl) => {
  const cookies = new Cookies();
  cookies.set('access-token', token, {
    domain: window.location.hostname,
    path: '/',
    maxAge: ttl,
    sameSite: 'Strict',
  });
};

export const getAuthCookie = () => {
  const cookies = new Cookies();
  return cookies.get('access-token');
};

export const logout = () => {
  removeAuthCookie();
};

const removeAuthCookie = () => {
  // const cookies = new Cookies();
  /*  cookies.remove('access-token', {
    domain: window.location.hostname,
    path: '/',
    sameSite: 'Strict',
  }); */
  // window.location.href = '/ms/ui-login/login';
};

export const isBigCommerce = (token) => {
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return axios.get(COMMERCE_URI, headers).then(({ data }) => {
    return data.big_commerce;
  });
};
