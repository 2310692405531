import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { registerApplication, start } from 'single-spa';

//TEMPORAL
import { initializeGTM } from './app/services/GTM';

const GTM = 'GTM-PWLD9T7';
initializeGTM(GTM);

const microfronts = [
  {
    name: 'menu',
    app: '@prisma/ui-portal-integraciones-menu',
    activeWhen: '/',
  },
  {
    name: 'header',
    app: '@prisma/ui-portal-integraciones-header',
    activeWhen: '/',
  },
  {
    name: 'integraciones',
    app: '@prisma/mf-portal-integraciones',
    activeWhen: '/',
  },
];

const configureMicrofronts = () => {
  microfronts.map((config) =>
    registerApplication({
      ...config,
      app: () => window.System.import(config.app),
      customProps: () => {},
    })
  );
};
configureMicrofronts();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

start();
