import React from 'react';
import { Provider } from 'react-redux';
import configureStore from '../../redux/store/configureStore';
import './LayoutTwo.css';

const Layout = ({ left, right, header, isPremium = false }) => {
  const store = configureStore({});
  return (
    <Provider store={store}>
      <div className='layout' id='layout'>
        <div className='layout__header'>{header}</div>
        <div
          className={`layout__navbar ${isPremium ? ' navbar--premium' : ''}`}
        >
          {left}
        </div>
        <main className='layout__content'>{right}</main>
      </div>
    </Provider>
  );
};

export default Layout;
