import { secureGet } from '../../utils/HttpUtils';

let userData = {
  data: {
    id: '78b0b357-fd2f-495b-9bd5-29629f2b3ad8',
    cuit: '33-55864363-9',
    email: 'usuario.owner@yopmail.com',
    business_name: 'HOTEL PRESIDENTE ROSARIO      ',
    full_name: null,
    roles: [
      'ROLE_offline_access',
      'ROLE_uma_authorization',
      'ROLE_admin',
      'ROLE_phe-free',
    ],
    permissions: [],
  },
};

export const getUserService = () => {
  if (Object.keys(userData).length > 0) return Promise.resolve(userData);

  return secureGet(`/api/bff-user/private/user`, {}).then((data) => {
    userData = data;
    return userData;
  });
};

export const getUserCommerces = () =>
  secureGet(`/api/bff-user/private/user/commerces`, {});
